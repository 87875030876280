import {
  OrderBy_AvailableFields,
  OrderBy_SortOrder,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { IssueStatus } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_ORDER_BY = OrderBy_AvailableFields.FIRST_OCCURRENCE;
export const DEFAULT_ORDER = OrderBy_SortOrder.DESC;
export const DEFAULT_ISSUE_STATUS = IssueStatus.OPEN;
export const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[2];
