import { Interceptor } from '@connectrpc/connect';
import { authService } from '~/services/auth/auth-service';
import { ENABLE_MOCKS } from '~/services/environment/environment';

export const authInterceptor: Interceptor = (next) => async (req) => {
  if (!ENABLE_MOCKS) {
    const token = await authService.getIdentityToken();

    if (token) {
      req.header.set('Authorization', `Bearer ${token}`);
    }
  }
  return next(req);
};
