import { aqoraClient } from '~/services/api/aqora-client';

export const userResetPassword = ({
  token,
  newPassword,
}: {
  token: string;
  newPassword: string;
}) =>
  aqoraClient.userSetupPasswordWithToken({
    token,
    newPassword,
  });
