import { t } from 'i18next';

export const validatePassword = (
  password: string,
  passwordConfirm: string,
  token: string,
) => {
  if (typeof password !== 'string') {
    throw new Error(t('auth.validatePasswordErrors.passwordNotAString'));
  }

  if (typeof passwordConfirm !== 'string') {
    throw new Error(
      t('auth.validatePasswordErrors.confirmationPasswordNotAString'),
    );
  }

  if (password.length < 6) {
    throw new Error(t('auth.validatePasswordErrors.passwordTooShort'));
  }

  if (password !== passwordConfirm) {
    throw new Error(t('auth.validatePasswordErrors.passwordsDoNotMatch'));
  }

  if (!token || token.length === 0) {
    throw new Error(t('auth.validatePasswordErrors.tokenDoesNotExist'));
  }
};
