import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { CardContent } from '@mui/material';
import { CloseIcon } from '~/shared/components/Icons/Icons';

interface ValidationProps extends CustomContentProps {
  errors?: string[];
}

export const Validation = forwardRef<HTMLDivElement, ValidationProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const { errors, message } = props;

    return (
      <SnackbarContent ref={ref}>
        <Card sx={{ backgroundColor: 'error.main', color: 'background.paper' }}>
          <CardActions
            sx={{
              gap: 3,
              padding: 2,
            }}
          >
            <Typography variant="body-s">{message}</Typography>

            <IconButton
              size="small"
              sx={{
                color: 'background.paper',
              }}
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </CardActions>
          {errors && errors.length > 0 && (
            <CardContent
              sx={{
                paddingTop: 0,
                ul: {
                  margin: 0,
                  padding: 0,
                  paddingLeft: 3,
                },
              }}
            >
              <ul>
                {errors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </CardContent>
          )}
        </Card>
      </SnackbarContent>
    );
  },
);

Validation.displayName = 'Validation';
