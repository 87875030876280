import { ActionFunction, redirect } from 'react-router-dom';
import { t } from 'i18next';
import { createRoutes } from '~/shared/models/create-routes';
import { validatePassword } from './validatePassword';
import { userResetPassword } from '~/pages/PasswordConfirm/api/userResetPassword.api';

export type CreateNewPasswordFormData = {
  password: string;
  passwordConfirm: string;
  token: string;
};

export const createPasswordAction =
  (): ActionFunction =>
  async ({ request }) => {
    const formData = await request.formData();
    const { password, passwordConfirm, token } = Object.fromEntries(
      formData.entries(),
    ) as CreateNewPasswordFormData;

    validatePassword(password, passwordConfirm, token);

    try {
      await userResetPassword({ token, newPassword: password });

      return redirect(createRoutes.SignIn);
    } catch (error) {
      throw new Error(t('auth.errors.createPasswordError'));
    }
  };
